import React from 'react';
import PropTypes from 'prop-types';
import { Button, Flex, HStack, Icon, Image, Text, useBreakpointValue, Wrap } from '@chakra-ui/react';
import { BiBookOpen, BiLinkExternal } from 'react-icons/bi';
import { AiOutlineCalendar } from 'react-icons/ai';
import { IoTrailSignOutline } from 'react-icons/io5';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { FaHubspot } from 'react-icons/fa';
import { strings } from 'business-dashboard/src/i18n';
import bambuLogo from '../../../resources/bambu.svg';

export const BAMBU_URL = 'https://app.getbambu.com/current';
export const HUBSPOT_URL = 'https://app.hubspot.com/contacts/3381692/deals';
export const LOOPCENTRAL_URL = 'https://loopcentral.getintheloop.com/learn';

const BIcon = ({ bg, color, icon, svg, ...rest }) => {
  if (icon) {
    return <Icon as={icon} w={7} h={7} p={1} color={color || 'white'} bg={bg} borderRadius='50%' {...rest} />;
  }
  if (svg) {
    return <Image src={svg} w={7} h={7} borderRadius='50%' {...rest} />;
  }

  return null;
};

BIcon.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.any,
  svg: PropTypes.string,
};

const QuickLink = ({
  item,
  href,
  target,
}) => (
  <Button
    as='a'
    variant='outline'
    justify='flex-start'
    height='56px'
    href={item.isDisabled ? undefined : href}
    target={item.isDisabled ? undefined : target}
  >
    <Flex justify='space-between' align='center' w='100%'>
      <Flex align='center'>
        <BIcon bg={item.color} icon={item.icon} svg={item.svg} />
        <Flex flexDir='column' ml={4}>
          <Text my={0} fontSize='lg' fontWeight={600}>{item.name}</Text>
          <Text
            mb={0}
            fontSize='lg'
            fontWeight={400}
            color='gray.600'
            fontStyle={`${item.isDisabled && 'italic'}`}
          >
            {!item.isDisabled ? item.description : strings('owners.component.quicklinks.comingSoon')}
          </Text>
        </Flex>
      </Flex>
      {(item.isExternal && !item.isDisabled) && <BIcon icon={BiLinkExternal} color='gray.400' ml={4} mb={4} />}
    </Flex>
  </Button>
);

QuickLink.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    icon: PropTypes.any,
    svg: PropTypes.string,
    description: PropTypes.string,
    isExternal: PropTypes.bool,
    isDisabled: PropTypes.bool,
  }),
  href: PropTypes.string,
  target: PropTypes.string,
};

const QuickLinks = () => {
  const items = [
    {
      name: strings('owners.component.quicklinks.calendar'),
      description: strings('owners.component.quicklinks.googleCalendar'),
      color: 'orange.400',
      icon: AiOutlineCalendar,
      href: 'https://calendar.google.com',
      isExternal: true,
    },
    {
      name: strings('owners.component.quicklinks.resourceCenter'),
      description: strings('owners.component.quicklinks.resourceCenterDescription'),
      color: 'purple.400',
      icon: BiBookOpen,
      href: LOOPCENTRAL_URL,
      isExternal: true,
    },
    {
      name: strings('owners.component.quicklinks.businessOperations'),
      description: strings('owners.component.quicklinks.businessOperationsDescription'),
      color: 'pink.400',
      icon: IoTrailSignOutline,
      href: '#business-operations',
    },
    {
      name: strings('owners.component.quicklinks.marketingRequests'),
      description: strings('owners.component.quicklinks.marketingRequestsDescription'),
      color: 'green.400',
      icon: HiOutlineSpeakerphone,
      href: '#marketing-requests',
    },
    {
      name: 'Bambu',
      description: strings('owners.component.quicklinks.bambuDescription'),
      svg: bambuLogo,
      href: BAMBU_URL,
      isExternal: true,
    },
    {
      name: 'Hubspot',
      description: strings('owners.component.quicklinks.pipedriveDescription'),
      color: 'gray.400',
      icon: FaHubspot,
      href: HUBSPOT_URL,
      isExternal: true,
    }
  ];

  const isSmallScreen = useBreakpointValue({ base: true, lg: false });
  const Wrapper = ({ children }) => {
    return isSmallScreen ? (
      <Flex w='100%' overflow='auto' pb={2}>
        <HStack spacing={4} p={1}>
          {children}
        </HStack>
      </Flex>
    ) : (
      <Wrap>{children}</Wrap>
    );
  };

  return (
    <Wrapper>
      {items.map(item => (
        <QuickLink
          key={item.name}
          item={item}
          href={item.href}
          target={item.isExternal && '_blank'}
        />
      ))}
    </Wrapper >
  );
};

export default QuickLinks;
