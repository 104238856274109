import React from 'react';
import { BoxProps, Flex, Icon, IconProps, List, ListItem, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { BiCopy, BiLinkExternal } from 'react-icons/bi';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { IoTrailSignOutline } from 'react-icons/io5';
import { strings } from 'business-dashboard/src/i18n';
import { HUBSPOT_URL, BAMBU_URL, LOOPCENTRAL_URL } from '../QuickLinks';
import WidgetHeader from '../widgets/WidgetHeader';

const LinkIcon = (props: IconProps & BoxProps): JSX.Element => (
  <Icon
    h={5}
    w={5}
    color='gray.700'
    p={1}
    ml={2}
    bg='gray.100'
    borderRadius='50%'
    {...props}
  />
);

const renderIcons = (isAction: boolean, isDisabled: boolean): JSX.Element => {
  if (isDisabled) return <></>;
  return isAction ? <LinkIcon as={BiCopy} /> : <LinkIcon as={BiLinkExternal} />;
};

const BusinessLinkItem = ({
  children,
  isAction = false,
  bottom,
  isDisabled = false,
  as,
  href
}: {
  children: React.ReactChild,
  isAction?: boolean,
  bottom?: boolean,
  isDisabled?: boolean,
  as?: any,
  href?: string,
}): JSX.Element => (
  <ListItem
    d='block'
    p={4}
    borderTop='1px'
    borderBottom={bottom ? '1px' : undefined}
    borderColor='gray.200'
    color='blue.600'
    fontWeight='400'
    fontSize='lg'
    target='_blank'
    _hover={{
      bg: 'gray.200',
      textDecoration: 'underline',
    }}
    as={as}
    href={isDisabled ? undefined : href}
  >
    <Flex align='center'>
      {children}&nbsp;
      {isDisabled && (
        <Text
          fontWeight='normal'
          fontStyle='italic'
        >
          {strings('owners.component.quicklinks.comingSoon')}
        </Text>
      )}
      {renderIcons(isAction, isDisabled)}
    </Flex>
  </ListItem>
);

const ListHeader = ({
  children,
  icon,
  iconColor,
  id,
  ...rest
}: {
  children: React.ReactChild,
  icon: any,
  iconColor?: string,
  id?: string,
}): JSX.Element => (
  <ListItem
    d='block'
    p={4}
    color='black'
    fontWeight='600'
    fontSize='16px'
    id={id}
    {...rest}
  >
    <Flex align='center'>
      <Icon as={icon} h={10} w={10} bg={iconColor} borderRadius='50%' p={2} color='white' mr={2} />
      {children}
    </Flex>
  </ListItem>
);

type LinkItem = {
  name: string;
  heading?: boolean;
  id?: string;
  icon?: any;
  iconColor?: string;
  href?: string;
  isDisabled?: boolean;
  isAction?: boolean;
  bottom?: boolean;
};

const BusinessLinks = (): JSX.Element => {
  const items: Array<Array<LinkItem>> = [
    [
      {
        name: strings('owners.component.quicklinks.marketingRequests'),
        heading: true,
        id: 'marketing-requests',
        icon: HiOutlineSpeakerphone,
        iconColor: 'green.400',
      },
      {
        name: strings('owners.component.quicklinks.customPush'),
        href: 'https://docs.google.com/forms/d/e/1FAIpQLSfxW7q-dwhZzy92_509IcSeg7hkbIDAdVck7MkQPqyVzogSNA/viewform'
      },
      {
        name: strings('owners.component.quicklinks.customizeDailyEmail'),
        href: 'https://docs.google.com/forms/d/e/1FAIpQLScEbn8aqEI-sedLObrxfozjcbbFCuAHWWt6CQsYLgPEEyJYcQ/viewform'
      },
      {
        name: strings('owners.component.quicklinks.singleOfferHighlights'),
        href: 'https://docs.google.com/forms/d/e/1FAIpQLSdbYlGp2wFvUyqNeJYrO0LYyl0WfqE0lW_RijwRLM0Fr9psvw/viewform'
      },
      {
        name: strings('owners.component.quicklinks.partnerSpotlights'),
        href: 'https://docs.google.com/forms/d/e/1FAIpQLSdbYlGp2wFvUyqNeJYrO0LYyl0WfqE0lW_RijwRLM0Fr9psvw/viewform'
      },
      {
        name: strings('owners.component.quicklinks.inAppContesting'),
        href: 'https://docs.google.com/forms/d/e/1FAIpQLSeEJl76glHo1ruogjA6omAdDNFFsdKvau3-AO1qpJH8WuEkDQ/viewform',
      },
      {
        name: strings('owners.component.quicklinks.loopTaggingRequest'),
        href: 'https://docs.google.com/forms/d/e/1FAIpQLScziHR1Oq6nhF-QqezJ-RwUGKNuEZCluGrXZKuLar7O2MlMfw/viewform',
      },
      {
        name: strings('owners.component.quicklinks.localCampaignStrategyAdBudgetRequest'),
        href: 'https://docs.google.com/forms/d/e/1FAIpQLScioKDwjzIlWO0uOd-5hT5Bk41u88-jqs2lGPz-Q6uJ3XDxXg/viewform',
      },
      {
        name: strings('owners.component.quicklinks.retailInsiderPrRequest'),
        href: 'https://docs.google.com/forms/d/e/1FAIpQLSd5jDrz76O3O0GKRVpQ1qSeDbEscpIrUmEEeBONecITTPeTHw/viewform',
        bottom: true,
      },
    ],
    [
      {
        name: strings('owners.component.quicklinks.businessOperations'),
        heading: true,
        id: 'business-operations',
        icon: IoTrailSignOutline,
        iconColor: 'pink.400',
      },
      {
        name: strings('owners.component.quicklinks.resourceCenter'),
        href: LOOPCENTRAL_URL
      },
      {
        name: 'Hubspot',
        href: HUBSPOT_URL,
      },
      {
        name: 'Bambu',
        href: BAMBU_URL,
      },
      {
        name: 'CDAP Business Application Form',
        href: 'https://docs.google.com/forms/d/e/1FAIpQLSfMpIGX3eeFiBcN9O-W0uD1jlGsYoivuQnQzPenHCVL0t6DOw/viewform',
      },
      {
        name: strings('owners.component.quicklinks.copyInviteLink'),
        href: 'https://loopcentral.getintheloop.com/share/asset/view/529'
      },
      {
        name: strings('owners.component.quicklinks.copyDownloadLink'),
        href: 'https://loopcentral.getintheloop.com/share/asset/view/529'
      },
      {
        name: strings('owners.component.quicklinks.pauseOrDowngradeRequest'),
        href: 'https://docs.google.com/forms/d/e/1FAIpQLSewkciPTFh4Z1z2qdUGprqhjdDL5998KFdujnhcgKu8-LcOaw/viewform'
      },
      {
        name: strings('owners.component.quicklinks.addRemoveEmployee'),
        href: 'https://docs.google.com/forms/d/e/1FAIpQLSdFifz8CACXgdttHnEVU9S0CMgmduaQ2RejXE1I-TWPWuTYaw/viewform',
      },
      {
        name: strings('owners.component.quicklinks.swagStore'),
        href: 'https://getinthelooplocal.com/shop',
      },
      {
        name: strings('owners.component.quicklinks.remittancesAndLegal'),
        href: 'https://www.dropbox.com/home',
      },

    ]
  ];

  const borderValue = useBreakpointValue({ base: 0, lg: '1px' });

  return (
    <Flex direction='column' border='1px' borderColor='gray.200' borderRadius={8}>
      <WidgetHeader title={strings('owners.dashboard.runMyBusiness')} />
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={0}
      >
        {items.map((category, idx) => (
          <List
            key={`businesslink-${idx}`}
            m={0}
            p={0}
            w='100%'
            borderLeftColor='gray.200'
            borderLeftWidth={idx > 0 ? borderValue : undefined}
          >
            {category.map((item) => {
              return item.heading ? (
                <ListHeader
                  key={item.name}
                  icon={item.icon}
                  iconColor={item.iconColor}
                  id={item.id}
                >
                  {item.name}
                </ListHeader>
              ) : (
                <BusinessLinkItem
                  isDisabled={item.isDisabled}
                  key={item.name}
                  as='a'
                  href={item.href}
                  isAction={item.isAction}
                  bottom={item.bottom}
                >
                  {item.name}
                </BusinessLinkItem>
              );
            })}
          </List>
        ))}
      </Stack>
    </Flex>
  );
};

export default BusinessLinks;
